import { IntroModel } from '@model/intro.model';
import { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid';

const ContentLayoutWrapper = (props: PropsWithChildren<IntroModel>) => {
  switch (props.fieldIntroLayout) {
    case 'left':
      return (
        <>
          <Grid item xs={0} lg={1} display={{ xs: 'none', lg: 'flex' }} />
          <Grid item lg={5} zIndex={1}>
            {props.children}
          </Grid>
          <Grid item lg={6} />
        </>
      );

    case 'right':
      return (
        <>
          <Grid item xs={0} lg={6} />
          <Grid item lg={5} zIndex={1}>
            {props.children}
          </Grid>
        </>
      );

    default:
      return (
        <>
          <Grid item xs={0} lg={1} />
          <Grid item lg={10} xs={12} zIndex={1}>
            {props.children}
          </Grid>
        </>
      );
  }
};

export default ContentLayoutWrapper;
